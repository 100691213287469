@import "compass";
@import "theme_variables";
@import "fonts";
/********************************************************
			Right to Left Styles
********************************************************/
* {
	direction: rtl !important;
}
#page {
	text-align: right !important;
}
.mr-4 {
	.lang-rtl & {
	    margin-left: 1.5rem !important;
	    margin-right: inherit !important;
	}
}
.mr-2 {
	.lang-rtl & {
	    margin-left: 0.5rem !important;
	    margin-right: inherit !important;
	}
}
.nov-policy.type-one img {
    margin-right: 25px !important;
}
.input-group .form-control:not(:first-child), .input-group-addon:not(:first-child), .input-group-btn:not(:first-child) > .btn, .input-group-btn:not(:first-child) > .btn-group > .btn, .input-group-btn:not(:first-child) > .dropdown-toggle, .input-group-btn:not(:last-child) > .btn-group:not(:first-child) > .btn, .input-group-btn:not(:last-child) > .btn:not(:first-child) {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
}
.input-group-btn:not(:first-child) > .btn, .input-group-btn:not(:first-child) > .btn-group {
    margin-right: -1px;
    margin-left: inherit;
}
@media (min-width:992px) {
    .offset-lg-4 {
        margin-right: 33.333333%;
        margin-left: inherit;
    }
    .offset-lg-3 {
        margin-right: 25%;
        margin-left: auto;
    }
    .text-lg-left {
        text-align: right !important;
    }
    .text-lg-right {
        text-align: left !important;
    }
}
@media (min-width:768px) {
    .text-md-right {
        text-align: left !important;
    }
    .text-md-left {
        text-align: right !important;
    }
}
@media (min-width: 767px){
    .lang-rtl{
        .nov-megamenu .menu > li.has-sub > .dropdown-menu .has-sub .opener:before{
            content: "\e91d"!important;
        }
    }
    
}
.list-inline {
	padding-right: 0;
	padding-left: inherit;
}
#block_advancedsearch {
    padding-left: 15px;
    padding-right: 0;
}
.account-list {
	left: 0;
	right: inherit;
}
.step-buy .step {
	background-position: right;
}

body.open_nov_mobile_menu {
    &.lang-rtl{
        #main-site {
            -webkit-transform: translateX(-250px);
            -ms-transform: translateX(-250px);
            transform: translateX(-250px);
        }
    }
}
.verticalmenu .menu li.item .dropdown-menu {
    text-align: right;
}
@media (min-width: 992px) {
	.verticalmenu .menu > li > .dropdown-menu {
		left: inherit;
		right: 100%;
	}
	.verticalmenu .menu > li > .dropdown-menu > ul > li > .dropdown-menu {
		right: 100%;
		left: inherit;
	}
}

.lang-rtl{
    .owl-nav{
        .owl-prev{
            i{
                &.icon-left-open-big{
                    &:before{   
                        content: "\e91e"!important;
                    }
                }    
            } 
        }
        .owl-next{
            i{
                &.icon-right-open-big{
                    &:before{
                        content: "\e91d"!important;
                    }
                }
            }
        }
        
    }
    .nivo-directionNav .nivo-nextNav{
        i{
            &.icon-left-open-big{
                &:before{
                    content: "\e91e"!important;
                }
            }
        }
    }
    .nivo-directionNav .nivo-prevNav{
        i{
            &.icon-right-open-big{
                &:before{   
                    content: "\e91d"!important;
                }
            }
        }
    }

    .content-featured{
        .slick-list{
            .slick-slide{
                float: right!important;
            }
        }
    }
    .page-home.pagehome-two .home-choose-us:after{
        background-image: url(../img/bg-choose-bf-rtl.png) !important;
    }
    .nov-image360deg .content-nov-image360deg .main-nov-image360deg .button_group .button_prev_360deg{
        i{
            webkit-transform: rotate(210deg)!important;
            -moz-transform: rotate(210deg)!important;
            -ms-transform: rotate(210deg)!important;
            -o-transform: rotate(210deg)!important;
            transform: rotate(210deg)!important;
            &:before{
                content: "\e8d3"!important;
            }
        }
    }
    .nov-image360deg .content-nov-image360deg .main-nov-image360deg .button_group .button_next_360deg{
        i{
            webkit-transform: rotate(-210deg)!important;
            -moz-transform: rotate(-210deg)!important;
            -ms-transform: rotate(-210deg)!important;
            -o-transform: rotate(-210deg)!important;
            transform: rotate(-210deg)!important;
            &:before{
                content: "\e8d1"!important;
            }
        }
    }
}
